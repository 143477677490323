<template>
  <div>
    <el-aside>
      <SideMenu :items="items"></SideMenu>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </div>
</template>

<script>
import SideMenu from "@/components/common/SideMenu";

export default {
  components: {
    SideMenu,
  },
  data() {
    return {
      items: [
        { icon: "el-icon-help", index: "test4-1", title: "需求综合展示" },
        
      ],
    };
  },
};
</script>

<style>
</style>
